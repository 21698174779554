<template>
  <div class="drvers">
    <UsersList :type="`drivers`">
      <PageTitle title="DRIVERS.TITLE" subtitle="DRIVERS.SUBTITLE">
        <Button @click="handleAddUSer">
          <span class="fs-16">{{ $t("GLOBAL.ADD_USER") }}</span>
          <img class="mx-2" src="@/assets/images/icons/add-user.svg" />
        </Button>
      </PageTitle>
    </UsersList>
  </div>
</template>
<script>
import UsersList from "@/components/Modules/Users/UsersList/index.vue"
import PageTitle from "@/components/Shared/PageTitle/index.vue"
export default {
  components: {
    UsersList,
    PageTitle
  }
}
</script>